import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'
import { ButtonContent } from '../global/boton/ButtonContent'


const CardProductBusqueda = ({imagen, name, price, phone}) => {
    const { rpdata } = useContext(GlobalDataContext)

    return (
        <div className='w-[250px] text-center shadow-lg pb-5 rounded-lg'>
            <div className='h-[250px] w-full pb-5'>
                <img src={imagen} alt="not-img" className='object-cover w-full h-full rounded-t-lg' />
            </div>
            <p>{name}</p>
            <p>{price}</p>
            <ButtonContent btnphone={`${rpdata?.dbPrincipal?.phones?.[0]?.phone}`} />
        </div>
    )
}

export default CardProductBusqueda