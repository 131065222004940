import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.gallery?.[5]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.gallery?.[4]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.gallery?.[1]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.gallery?.[2]}")` }}
        ></figure>
        <div className="z-10 relative w-4/5 mx-auto pt-[350px] pb-[100px] md:pb-[150px] md:pt-[380px] flex md:flex-row flex-col  ">
          <div className="w-full md:w-[60%] flex flex-col md:justify-center justify-start">
            <h1 className="text-white text-center md:text-start">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <p className="text-white text-center md:text-start">{rpdata?.dbValues?.[1].description}</p>
            <div className="text-center md:text-start">
              <ButtonContent />
            </div>
          </div>
          <div className="md:w-[40%] w-full md:h-auto h-[200px] flex justify-center md:items-start items-end">
            <img className="md:w-[300px] md:h-[300px] w-[150px] h-[150px]" src="https://firebasestorage.googleapis.com/v0/b/videos-a7d6e.appspot.com/o/Best%20Painters%20of%20Florida-39%20a%C3%B1os.png?alt=media&token=168632a5-a060-4a0b-b2ce-08c94bfc9a78"/>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
